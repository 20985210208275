import {
	loginData,
	responseToken,
	responseUserMe,
	responseUsersByProject,
	searchData,
	responseSearchUser,
	addUserData,
	removeUserData,
	addExternalUserData, changePasswordData
} from './transform/UserTransform';
import {handlerError, ApiService} from "../ApiService";
import {AUTH_TOKEN} from "../../constants/Auth";
import utils from "../../../utils";
import {notification} from "antd";
import {showAuthMessage} from "../../actions/Auth";
const UserService = {};

UserService.login = (data) =>
	ApiService.post('', loginData(data))
		.then(responseToken)
		.catch(handlerErrorLogin);

UserService.getUserMe = () =>
	ApiService.post('', { tipo: "OPERACION_1-2" })
		.then(responseUserMe)
		.catch(handlerError);

UserService.getUsersByProject = () =>
	ApiService.post('', {tipo: "OPERACION_13-1"})
		.then(responseUsersByProject)
		.catch(handlerError)

UserService.searchUser = (data) =>
	ApiService.post('', searchData(data))
		.then(responseSearchUser)
		.catch(handlerError)

UserService.addUser = (data) =>
	ApiService.post('', addUserData(data))
		.catch(handlerError)

UserService.addExternalUser = (data) =>
	ApiService.post('', addExternalUserData(data))
		.catch(handlerError)

UserService.changePassword = (data) =>
	ApiService.post('', changePasswordData(data))
		.catch(handlerError)

UserService.removeUser = (data) =>
	ApiService.post('', removeUserData(data))
		.catch(handlerError)

export const handlerErrorLogin = (error) => {
	let notificationParam = { message: '' };

	// Remove token and redirect
	if (error.response.status === 401) {
		notificationParam.message = 'Authentication Fail';
		notificationParam.description = 'Please login again';
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}

	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam);
	showAuthMessage(notificationParam.message);

	return Promise.reject(error);
};

export default UserService