import { Widget } from './index';
import {Table} from "antd";
import React from "react";
import Card from "antd/es/card";

export class WidgetText extends Widget {

  constructor(obj={}){
    super(obj);

    this.value = obj.value;
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <div dangerouslySetInnerHTML={{ __html: this.value }}></div>
      </Card>
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    return this;
  }
}