import { Widget } from './index';
import {Collapse, Table} from "antd";
import React from "react";
import Card from "antd/es/card";

const { Panel } = Collapse;

export class WidgetCollapse extends Widget {

  constructor(obj={}){
    super(obj);
  }
  getComponent() {
    return () => (
      <Card title={this.title}>
        <Collapse bordered={false}>
          {this.value.map(collapse => <Panel header={collapse.header}>
            <p style={{ paddingLeft: 24 }}> <div dangerouslySetInnerHTML={{ __html: collapse.text }}></div></p>
          </Panel>)}
        </Collapse>

      </Card>
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    return this;
  }
}