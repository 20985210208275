import {GET_CHAT, GET_CHAT_SUCCESS} from "../constants/Chat";

export const getChat = () => {
  return {
    type: GET_CHAT,
  };
};

export const getChatSuccess = (chat) => {
  return {
    type: GET_CHAT_SUCCESS,
    payload: chat
  };
};