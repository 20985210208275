import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'services/actions/Auth';
import utils from "../../utils";
import { useHistory } from "react-router-dom";



const menuItem = [
	{
		title: "Edit Profile",
		icon: EditOutlined ,
		path: "/"
    },
    
    {
		title: "Account Setting",
		icon: SettingOutlined,
		path: "/"
    },
    {
		title: "Billing",
		icon: ShopOutlined ,
		path: "/"
	},
    {
		title: "Help Center",
		icon: QuestionCircleOutlined,
		path: "/"
	}
]



export const NavProfile = ({user, project, signOut}) => {
  let history = useHistory();

  const backToSelectProject = () => {
    history.push({pathname:`/app/global`})
  };

  const backToLogin = () => {
    history.push({pathname: utils.getRouteLogin()})
  };
  const profileImg = "https://cdn-icons-png.flaticon.com/512/6009/6009695.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{user.username}</h4>
            <span className="text-muted">{project?.rol}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/*{menuItem.map((el, i) => {*/}
            {/*return (*/}
              {/*<Menu.Item key={i}>*/}
                {/*<a href={el.path}>*/}
                  {/*<Icon className="mr-3" type={el.icon} />*/}
                  {/*<span className="font-weight-normal">{el.title}</span>*/}
                {/*</a>*/}
              {/*</Menu.Item>*/}
            {/*);*/}
          {/*})}*/}
          <Menu.Item key={1} onClick={e => backToSelectProject()}>
            <span>
              <RollbackOutlined className="mr-3"/>
              <span className="font-weight-normal">Cambiar de proyecto</span>
            </span>
          </Menu.Item>
          <Menu.Item key={2} onClick={e => {
            signOut();
            backToLogin()
          }}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Cerrar sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <SettingOutlined className="nav-icon mr-0" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
    project: auth.projects.find(o => o.id === utils.getIdProject()),
  };
};
export default connect(mapStateToProps, {signOut})(NavProfile)
