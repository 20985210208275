import {ApiService, handlerError} from "../ApiService";
import {responseSetting} from "./transform/SettingTransform";

const SettingService = {};

SettingService.get = () => {
  return ApiService.post('', {tipo: "OPERACION_0"})
    .then(responseSetting)
    .catch(handlerError);
}



export default SettingService