import {Widget} from "./index";
import React from "react";
import ChartWidget from "../../components/shared-components/ChartWidget";
import moment from "moment";

export class WidgetChart extends Widget {

  constructor(obj = {}) {
    super(obj);

    this.value = obj.value;
    this.series = obj.series;
    this.categories = obj.categories;
    this.height = obj.height ? obj.height : 400;
    this.chartType = obj.chartType;
    this.customOptions = obj.customOptions;
  }

  getComponent() {
    return () => (
      <ChartWidget
        title={this.title}
        series={this.transformDateSeries()}
        xAxis={this.categories}
        height={this.height}
        type={this.chartType}
        customOptions={this.transformCustomOptions()}
      />
    );
  }

  transformDateSeries = (series=this.series) => {
     if(this.chartType == 'temporal'){
        series.map(element => {
          element.data.map(this.transformDate)
        })
     }
    if(this.chartType == 'rangeBar'){
      series.map(element => {
        element.data.map(this.transformDateRangeBar)
      })
    }
    return series;
  }

  transformCustomOptions = (customOptions=this.customOptions) => {
    let typeOfObject
    if(customOptions?.annotations?.xaxis?.length > 0)
      typeOfObject = typeof customOptions?.annotations?.xaxis[0]?.x
    if(customOptions?.annotations?.xaxis?.length && typeOfObject != "number" ) {
      customOptions.annotations.xaxis.map(this.transformAnnotationToDate)
      customOptions.xaxis= {
        type: 'datetime'
      }
    }
    return customOptions;
  }

  transformAnnotationToDate = (annotation) => {
    annotation.x = new Date(moment.utc(annotation.x).format("YYYY-MM-DD h:mm")).getTime();
    return annotation;
  }

  transformDate = (dateArray) =>{
    dateArray[0] = moment.utc(dateArray[0]).format("YYYY-MM-DD h:mm")
    return dateArray;
  }
  transformDateRangeBar = (element) =>{
    element.y[0] = new Date(moment.utc(element.y[0]).format("YYYY-MM-DD h:mm")).getTime();
    element.y[1] = new Date(moment.utc(element.y[1]).format("YYYY-MM-DD h:mm")).getTime();
    return element;
  }
  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.series = data.series;
    this.categories = data.categories;
    this.height = data.height ? data.height : 400
    this.chartType = data.chartType;
    this.customOptions = data.customOptions;
    return this;
  }
}