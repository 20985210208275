import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "services/actions/Theme";
import * as AntdIcons from '@ant-design/icons';
import { useHistory } from "react-router-dom";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// const setDefaultOpen = (key) => {
//   let keyList = [];
//   let keyString = "";
//   if (key) {
//     const arr = key.split("-");
//     for (let index = 0; index < arr.length; index++) {
//       const elm = arr[index];
//       index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
//       keyList.push(keyString);
//     }
//   }
//   return keyList;
// };

const SideNavContent = (props) => {
  const history = useHistory();
	const { sideNavTheme, hideGroupTitle, onMobileNavToggle } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}

	};

	const [project, setProject] = useState(props.project);
	const [patient, setPatient] = useState(props.patient);
	const [idPatient, setIdPatient] = useState(undefined)

	useEffect(() => {
    setProject(props.projects.find(o => o.id === utils.getIdProject() && o.hospital.id === utils.getIdHospital()));
	  setPatient(props.patient)
    setIdPatient(utils.getPatientLocation())
  },[props]);

  const goTo = (path) => {
    // return utils.getPathWithSearch(`${utils.getInitRootProject()}/${path}`)
    history.push(utils.getRedirectWithPatient(path));
    // history.push({
    //   pathname:'/app/cronicare/home',
    //   search:'?asd=dsa'
    // });
  };

  const goToClinical = (url) => {
    let urlReplaced = url.replace("XXXUSUARIOXXX", props.user);
    urlReplaced = urlReplaced.replace("XXXNUHSAXXX", patient.nuhsa)
    window.open(urlReplaced);
  }

  const goToExternal = (url) => {
    window.open(url)
  }
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      selectedKeys={[utils.getLinkPathname()]}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {project?.menu.map((menu) =>
        menu.submenu?.length > 0 ? (
          <Menu.ItemGroup
            key={menu.url}
            title={menu.name}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                // Submenu Parent
                <SubMenu
                  icon={<Icon type={AntdIcons['UpOutlined']} />}
                  // icon={
                  //   subMenuFirst.icon ? (
                      //<Icon type={subMenuFirst?.icon} />
                    // ) : null
                  // }
                  key={subMenuFirst.name}
                  title={subMenuFirst.name}
                  disabled={!idPatient}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    // Submenu child
                    <Menu.Item key={subMenuSecond.url} disabled={!idPatient && subMenuFirst.needPatient}>
                      {/*{subMenuSecond.icon ? (*/}
                        {/*<Icon type={subMenuSecond?.icon} />*/}
                      {/*) : null}*/}

                      <Icon type={AntdIcons['VerticalAlignTopOutlined']} />
                      <span>
                        {subMenuSecond.name}
                      </span>
                      {subMenuSecond.modeView === 'default' && subMenuSecond.url ? <a onClick={() => {closeMobileNav();goTo(subMenuSecond.url)}}/> : null}
                      {subMenuSecond.modeView === 'popUp' && subMenuSecond.url ? <a onClick={() => goToClinical(subMenuSecond.url)} /> : null}
                      {subMenuSecond.modeView === 'tab' && subMenuSecond.url ? <a onClick={() => goToExternal(subMenuSecond.url)} /> : null}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                // Menu
                <Menu.Item key={subMenuFirst.url} disabled={!idPatient && subMenuFirst.needPatient}>
                  {/*{subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}*/}

                  <Icon type={AntdIcons[subMenuFirst.icon]} />
                  <span>{subMenuFirst.name}</span>
                  {subMenuFirst.modeView === 'default' && subMenuFirst.url ? <a onClick={() => {closeMobileNav();goTo(subMenuFirst.url)}}/> : null}
                  {subMenuFirst.modeView === 'popUp' && subMenuFirst.url ? <a onClick={() => goToClinical(subMenuFirst.url)} /> : null}
                  {subMenuFirst.modeView === 'tab' && subMenuFirst.url ? <a onClick={() => goToExternal(subMenuFirst.url)} /> : null}
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.url} disabled={!idPatient && menu.needPatient}>
            {/*{menu.icon ? <Icon type={menu?.icon} /> : null}*/}
            <Icon type={AntdIcons[menu.icon]} />
            <span>{menu?.name}</span>
            {menu.modeView === 'default' && menu.url ? <a onClick={() => goTo(menu.url)} /> : null}
            {menu.modeView === 'popUp' && menu.url ? <a onClick={() => window.open(menu.url.replace("XXXUSUARIOXXX", props.user),'Data','height=500,width=500')} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {

  // useEffect(() => {
  //   console.log('2-Renderiza MenuContent')
  // });
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme , auth, patient }) => {
  const { sideNavTheme, topNavColor } = theme;
  return {
    sideNavTheme,
    topNavColor,
    project: auth.projects.find(o => o.id === utils.getIdProject() && o.hospital.id === utils.getIdHospital()),
    projects: auth.projects,
    user: auth.user.username,
    refresh: auth.refreshMenu,
    patient: patient.patient,
  };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
