import React from 'react';
import { Widget } from './index';
import Card from "antd/es/card";
import TableSearch from "../../components/shared-components/TableSearch";
import {transformColumn} from "../../utils/TransformActionTable";

export class WidgetTableSearch extends Widget {

  constructor(obj={}){
    super(obj);

    this.headers = obj.headers;
    this.rows = obj.rows;
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <TableSearch data={this.transformDataSource()} columns={transformColumn(this.headers)} pagination={this.pagination}/>
      </Card>
    );
  }


  transformDataSource(rows=this.rows) {
    return rows
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.headers = data.header;
    this.rows = data.rows;
    this.pagination = data.pagination || data.pagination==undefined ? {hideOnSinglePage: true, defaultPageSize: 10} : false

    return this;
  }

}