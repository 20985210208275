export const responseSetting = ({data}) => {
  const project = data.datos;
  const res = {};

  res.info = {
    code: project.abreviatura,
    description: project.descripcion,
    image: project.logo,
    name: project.nombre,
    title: project.titulo
  };

  res.menu = project.modulos.map(transformMenu)

  res.roles = project.mapeo_roles.map(transformRole)

  res.options = {
    randomize: project.aleatorizador,
    app: project.app,
    docs: project.barra_docs,
    remove: project.borrar,
    ce_ci: project.ce_ci,
    editable: project.editable,
    hce: project.hce,
    second_id: project.id_secundario,
    special_table: project.p_table,
    external_patient: project.paciente_externo
  }

  return project;
}

const transformMenu = (module) => {
  return {
    description: module.descripcion,
    active: module.activo,
    id: module.id_modulo,
    name: module.nombre,
    need_patient: module.paciente_seleccionado,
    icon: module.ruta_logo,
    link: module.url
  }
}

const transformRole = (role) => {
  return {
    id: role.id_rol,
    name: role.nombre_rol
  }
}
