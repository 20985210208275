import React from 'react'
import {Col, Form, Input} from "antd";
import {Field} from "./index";
import Utils from "../../utils";
import {LabelWithTooltip} from "./components/LabelWithTooltip";

export class FieldCalculated extends Field {

  constructor(obj={}) {
    super(obj);
    this.needRefresh = true;
  }

  getComponent (form, changed) {
    form.setFieldsValue	({[this.name]: Utils.evaluateFormula(this.formula, form)})
    return () => (
      <Col className={!Utils.evaluateFormula(this.condition, form) ? "display-none" : "visible"} xs={24} sm={24} md={this.size}>

      <Form.Item
          label={<LabelWithTooltip label={this.label} help={this.help}/>}
          name={this.name}
          initialValue={this.value}
        >
          <Input
            disabled={this.disabled}
          />
        </Form.Item>
      </Col>);
  }

  transformToField = (data) => {
    console.log('formula', data)
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.value = data.value ?? "";
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    this.formula = data.formula ?? 0;
    return this;
  }
}