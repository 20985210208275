import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import FormService from "../api/form";
import {getDashboardError, getDashboardSuccess} from "../actions/Dashboard";
import {GET_FORM} from "../constants/Form";
import {getFormError, getFormSuccess} from "../actions/Form";

export function* requestForm() {

	yield takeEvery(GET_FORM, function* ({payload, fake}) {
		try {
			const response = yield call(FormService.get, { payload, fake });
			if (response.message) {
				// TODO implement generic error dashboard
				// yield put(showErrorMessage(response.message));
			} else {
				yield put(getFormSuccess(response));
			}
		} catch (error) {
			// TODO implement generic error dashboard
			yield put(getFormError());
			// yield put(showErrorMessage('api.error.login'));
		}
	})
}

export default function* rootSaga() {
  yield all([
		fork(requestForm)
  ]);
}
