import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import utils from "../../utils";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/search`} component={lazy(() => import(`./search-patient`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/dashboard/:dashboard/:secondId?`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/form/:form/:secondId?`} component={lazy(() => import(`./form`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/dashboard-creator`} component={lazy(() => import(`./dashboard-creator`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/dashboard-documentation`} component={lazy(() => import(`./dashboard-documentation`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/chat`} component={lazy(() => import(`./chat`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/exportation`} component={lazy(() => import(`./exportation`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/importation`} component={lazy(() => import(`./importation`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/users`} component={lazy(() => import(`./user-list`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/essay`} component={lazy(() => import(`./setting`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/documenteditor/:iframe`} component={lazy(() => import(`./iframe`))} />
        <Route path={`${APP_PREFIX_PATH}/:project/:hospital/board`} component={lazy(() => import(`./scrumboard`))} />


        <Route path={`${APP_PREFIX_PATH}/:project/selector`} component={lazy(() => import(`./hospital-selector`))} />

        <Redirect from={`${APP_PREFIX_PATH}/:project/:hospital`} to={utils.getPathWithSearch(`${APP_PREFIX_PATH}/:project/:hospital/home`)} />
        <Redirect from={`${APP_PREFIX_PATH}/:project`} to={utils.getPathWithSearch(`${APP_PREFIX_PATH}/:project/selector`)} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
