import React from 'react'
import {Form, DatePicker, Col} from "antd";
import {Field} from "./index";
import moment from "moment";
import Utils from "../../utils";

const { MonthPicker} = DatePicker;

export class FieldMonthDate extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form) {
    return () => (
      <Col className={!Utils.evaluateFormula(this.condition, form) ? "display-none" : "visible"} xs={24} sm={24} md={this.size}>

      <Form.Item
        label={this.label}
        name={this.name}
      >
        <MonthPicker placeholder="Seleccione mes" style={{ width: '100%' }}/>

        {/*<DatePicker format={dateFormat} style={{ width: '100%' }}/>*/}
      </Form.Item>
      </Col>)
      ;
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;

    return this;
  }

  transformValueToField = (data) => moment(data,"DD-MM-YYYY");

}


