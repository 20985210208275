import React from 'react';
import { Widget } from './index';
import {Button, Col, Divider, message, Table, Tooltip} from "antd";
import Card from "antd/es/card";

import { CopyOutlined } from '@ant-design/icons';

import {transformColumn} from "../../utils/TransformActionTable";
import uuid from 'react-uuid';
import CopyToolbar from "../../components/util-components/DemoCard/CopyToolbar";



export class WidgetTable extends Widget {

  constructor(obj={}){
    super(obj);

    this.headers = obj.headers;
    this.rows = obj.rows;
    // this.id = uuid();
  }

  getComponent() {
    return () => {

      const transformDataSource = (rows=this.rows) => {
        return rows
      }


      return (
        <Card title={this.title} >
          <Table
            // id={this.id}
            className={this.alternateColor ? 'alternate-rows' : ''}
            columns={transformColumn(this.headers)}
            dataSource={transformDataSource()}
            scroll={{ y: this.scrollY, x: 1 }}
            pagination={this.pagination}
            bordered={this.bordered}
            size={this.tableSize}
          />
          {/*{this.copy && <CopyToolbar id={this.id}/>}*/}
        </Card>
      );
    }
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.headers = data.customOptions?.header ?? data.header;
    this.tableSize = data.table_size ?? "middle";
    this.rows = data.rows;
    this.scrollY = data.scrollY;
    this.bordered = data.customOptions?.bordered ?? data.bordered ?? false;
    this.copy = data.copy ?? false
    this.alternateColor = data.customOptions?.alternateColor ?? false;
    this.pagination = data.pagination || data.pagination==undefined ? {hideOnSinglePage: true, defaultPageSize: 10} : false
    return this;
  }

}