import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Dashboard from './Dashboard';
import Chat from './Chat'
import Setting from './Setting'
import Form from './Form'


export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Dashboard(),
    Chat(),
    Setting(),
    Form(),
  ]);
}
