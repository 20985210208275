import React from 'react'
import {Col, Form, Radio} from "antd";
import {Field} from "./index";
import Utils from "../../utils";
import {FieldBase} from "./components/FieldBase";

export class FieldRadio extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form, fieldChanged) {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    const handleClick = (e) => {
      if(e.target.value===form.getFieldValue(this.name)) {
        form.setFieldsValue({[this.name]: ""})
        fieldChanged();
      }
    }
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help}>

          <Radio.Group>
            {this.options?.map(option => <Radio onClick={handleClick} style={radioStyle} value={option.key}>{option.label}</Radio>)}
          </Radio.Group>
      </FieldBase>
    );
  }


  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.options = data.options;
    this.value = data.value ?? undefined;
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    return this;
  }
}