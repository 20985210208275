import {GET_FORM, GET_FORM_SUCCESS, SHOW_FORM_LOADING, GET_FORM_ERROR, FIELD_CHANGED, GET_FORM_ROW} from "../constants/Form";

export const getForm = (id, fake) => {
  return {
    type: GET_FORM,
    payload: id,
    fake
  };
};

export const getFormSuccess = (form) => {
  return {
    type: GET_FORM_SUCCESS,
    payload: form
  };
};
export const getFormError = () => {
  return {
    type: GET_FORM_ERROR,
  };
};

export const showFormLoading = () => {
  return {
    type: SHOW_FORM_LOADING,
  };
};

export const fieldChanged = () => {
  return {
    type: FIELD_CHANGED,
  };
};

export const selectFormRow = (form) => {
  return {
    type: GET_FORM_ROW,
    payload: form
  };
};


