import {Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import React from "react";

export const LabelWithTooltip = ({label, help}) => {

  return (help ?
    <span>
     {label}&nbsp;
     <Tooltip title={<div className="content" dangerouslySetInnerHTML={{__html: help}}></div>}>
     <QuestionCircleOutlined />
     </Tooltip>
    </span> :
    <span>{label}</span>)

}