import {ApiService, handlerError} from "../ApiService";
import {getSendData, readData, responseChat} from "./transform/ChatTransform";

const ChatService = {};

ChatService.get = () => {
  return ApiService.post('', {tipo: "OPERACION_15-1"})
    .then(responseChat)
    .catch(handlerError);
}

ChatService.sendMessage = (message) => {
  return ApiService.post('', getSendData(message))
    .then()
    .catch(handlerError);
}

ChatService.readMessages = (chat) =>
  ApiService.post('', readData(chat))
    .catch(handlerError);


export default ChatService