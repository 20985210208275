import React from 'react'
import {Form, DatePicker, Col} from "antd";
import {Field} from "./index";
import moment from "moment";
import Utils from "../../utils";

export class FieldRangeDate extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form) {
    const { RangePicker } = DatePicker;
    const dateFormat = ['DD/MM/YYYY'];

    return () => (
      <Col className={!Utils.evaluateFormula(this.condition, form) ? "display-none" : "visible"} xs={24} sm={24} md={this.size}>

      <Form.Item
        label={this.label}
        name={this.name}
      >
        <RangePicker format={dateFormat} style={{ width: '100%' }}/>
      </Form.Item>
      </Col>);
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.condition = data.condition ?? true;
    return this;
  }

  transformValueToField = (data) => {
    let result = data.split(',');
    result[0] = moment(result[0],"DD/MM/YYYY");
    result[1] = moment(result[1],"DD/MM/YYYY");
    return result;
  }

}


