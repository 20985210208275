import React from 'react'
import {Field} from "./index";
import {Col} from "antd";


export class FieldBlank extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent () {
    return () => (
      <Col xs={24} sm={24} md={this.size}>
        <div></div>
      </Col>);

  }

  transformToField = (data) => {
    this.type = data.type;
    this.size = data.size ?? this.size;
    return this;
  }
}