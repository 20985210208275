import React, {useEffect, useState} from "react";
import Card from "antd/es/card";
import {Divider, Table, Tag} from "antd";
import Chart from "react-apexcharts";
import ChartWidget from "../ChartWidget";
import utils from "../../../utils";
import moment from "moment";

export const TableWithChart = ({columns, data}) => {
  //
  // const columns = [
  //   {
  //     title: 'Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //     onHeaderCell: (column) => {
  //       return {
  //         onClick: () => {
  //           getColumnValues(column.dataIndex);
  //         }
  //       };
  //     },
  //     render: text => <a>{text}</a>,
  //   },
  //   {
  //     title: 'Age',
  //     dataIndex: 'age',
  //     key: 'age',
  //     onHeaderCell: (column) => {
  //       return {
  //         onClick: () => {
  //           getColumnValues(column.dataIndex);
  //         }
  //       };
  //     },
  //   },
  //   {
  //     title: 'Address',
  //     dataIndex: 'address',
  //     key: 'address',
  //     onHeaderCell: (column) => {
  //       return {
  //         onClick: () => {
  //           getColumnValues(column.dataIndex);
  //         }
  //       };
  //     },
  //   },
  //   {
  //     title: 'Tags',
  //     key: 'tags',
  //     dataIndex: 'tags',
  //     render: tags => (
  //       <span>
  //       {tags.map(tag => {
  //         let color = tag.length > 5 ? 'geekblue' : 'green';
  //         if (tag === 'loser') {
  //           color = 'volcano';
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </span>
  //     ),
  //   },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record) => (
  //       <span>
  //       <a>Invite {record.name}</a>
  //       <Divider type="vertical" />
  //       <a>Delete</a>
  //     </span>
  //     ),
  //   },
  // ];
  // const data = [
  //   {
  //     key: '1',
  //     name: '23',
  //     age: '32',
  //     address: '300',
  //     tags: ['nice', 'developer'],
  //   },
  //   {
  //     key: '2',
  //     name: '31',
  //     age: '42',
  //     address: '200',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '3',
  //     name: '',
  //     age: '32',
  //     address: '100',
  //     tags: ['cool', 'teacher'],
  //   },
  // ];

  const getDateValue = () => {
    return data.map((element) => element.date);
  }
  const [dataChart, setDataChart] = useState(
    {
      series: [{
        name: 'series1',
        data: [31, 40, 28]
      }],
      options: {
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: getDateValue()
        },
        tooltip: {
          x: {
            format: 'dd/MM/yyyy'
          },
        },
      }
    }
  );

  const getColumnValues = (columnIndex) => {
    console.log(dataChart.series[0].data)
    let auxData = JSON.parse(JSON.stringify(dataChart));
    auxData.series[0].data = data.map((element) => element[columnIndex]);
    setDataChart(auxData);
  }



  const transformColumn = (headers=columns) => {
    return headers.map(o => ({
      title: o.title,
      dataIndex: o.dataIndex,
      key: o.key,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            getColumnValues(column.dataIndex);
          }
        };
      },
    }))
  }

  useEffect(() => {
  }, [dataChart])
  return (
    <>
        <Chart
          series={dataChart.series}
          options={dataChart.options}

          type="area"
          height= {300}
        />
        <Table columns={transformColumn()} dataSource={data} scroll={{ x: 1 }}/>
    </>
  );
}