import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Innovación';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const HEADER_TOKEN = 'token';
export const PROJECT_GLOBAL = 'global';
export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'es',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#ffffff',
	mobileNav: false
};
export const PROJECTS = {
	global: null,
	smartmigraine: '28',
	cronicare: '29',
	hero: '30',
	ictus: '6',
	rinobase: 43,
	gestion: '49',
	info: '54',
	dashboard: '56',
	reuma: '47',
	oncoand: '51',
	pointofcare: '59',
	geohealth: '44',
	sicapv: '2',
	reumatolog: '47',
	alergolog: '61',
	calidad: '63',
	preanestesas: '19',
	tecipot: '64',
	gestionhospitalaria: '65',
	fracturas: '66',
	direccion: '68',
	peticiones: '69',
	prostata: '55',
	biobanco: '71',
	unidades: '72',


	ginecologia	:	"73"	,
	orl	:	"74"	,
	salmental	:	"75"	,
	digestivo	:	"76"	,
	alergologia	:	"77"	,
	derma	:	"78"	,
	cped	:	"79"	,
	cot	:	"80"	,
	endocrino	:	"81"	,
	urgencias	:	"82"	,
	hematologia	:	"83"	,
	cplast	:	"84"	,
	medint	:	"85"	,
	mednuc	:	"86"	,
	nefrologia	:	"87"	,
	ctorac	:	"88"	,
	oncomed	:	"89"	,
	anestesiologia	:	"90"	,
	ccv	:	"91"	,
	neurologia	:	"92"	,
	onco_rad	:	"93"	,
	eeii	:	"94"	,
	oftalmologia	:	"95"	,
	cuidadosintensivos	:	"96"	,
	radiodiagnostico	:	"97"	,
	ap	:	"98"	,
	pediatría	:	"99"	,
	cmax	:	"100"	,
	cgral	:	"101"	,
	urologia	:	"102"	,

	bacteriemias: "103",
	neurofisiologia: "105",
	"misiones_a1": "116",

	"dir_economica": "178",
	app_enf: "180",
	unicom: "176",
	app_direccion: "174",
	primavera: "175",


};

export const DESCRIPTIONS = {
	global: 'GLOBAL ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	cronicare: 'cronicare ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	hero: 'hero ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	ictus: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	gestion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	info: 'Proyecto de gestion de información.',
	dashboard: 'Proyecto de gestion de información.',
	reuma: 'Proyecto de Rehumatología.',
	oncoand: 'Proyecto de investigación para la definición, implementación y validación de un sistema de Learning Health System orientado al soporte en la toma de decisiones de los comités de tumores del Hospital Universitario Virgen Macarena\n.',
	geohealth: 'Victor desertor',
	sicapv: 'SICAPV+ es una solución tecnológica de soporte a la gestión y el seguimiento de PVCV en Andalucía de manera integrada con la práctica clínica habitual ',
	reumatolog: 'Estudio Reuma',
	calidad: 'El objetivo principal de la Unidad de Calidad es desarrollar las actividades necesarias para la gestión de la calidad en nuestro Hospital, orientando siempre el trabajo a la mejora continua de la calidad asistencial prestada a nuestros usuarios.',
	preanestesas: 'Este proyecto plantea una mejora al proceso asociado a la evaluación preanestésica, promoviendo el desarrollo de nuevos servicios integrados de atención a través de los terminales móviles de los pacientes y permitiendo así reducir el consiguiente consumo de recursos sanitarios asociado.',
	tecipot: 'Proyecto Tecipot.',
	fracturas: 'Proyecto Fracturas.',
	gestionhospitalaria: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	direccion: 'Cuadro de mando para dirección',
	alergolog: 'Proyecto de Alergología',
	peticiones: 'Peticiones',
	prostata: 'Proyecto ACPROAND',
	smartmigraine: 'SmartMigraine',
	biobanco: 'Proyecto Biobanco',
	rinobase: 'Proyecto Rinobase',
	unidades: 'Proyecto Unidades',


	ginecologia	:	"Unidad Ginecologia"	,
	orl	:	"Unidad ORL"	,
	salmental	:	"Unidad Salud Mental"	,
	digestivo	:	"Unidad Digestivo"	,
	alergologia	:	"Unidad Alergologia"	,
	derma	:	"Unidad Dermatologia"	,
	cped	:	"Unidad Cirugia Pediatrica"	,
	cot	:	"Unidad Cirugia OyT"	,
	endocrino	:	"Unidad Endocrino"	,
	urgencias	:	"Unidad Urgencias"	,
	hematologia	:	"Unidad Hematologia"	,
	cplast	:	"Unidad Cirugia Plastica"	,
	medint	:	"Unidad Medicina Interna"	,
	mednuc	:	"Unidad Medicina Nuclear"	,
	nefrologia	:	"Unidad Nefrologia"	,
	ctorac	:	"Unidad Cirugia Toracica"	,
	oncomed	:	"Unidad Oncologia Medica"	,
	anestesiologia	:	"Unidad Anestesiologia"	,
	ccv	:	"Unidad Cardiologia y CCV"	,
	neurologia	:	"Unidad Neurologia"	,
	onco_rad	:	"Unidad Onco Radioterapica"	,
	eeii	:	"Unidad EEII"	,
	oftalmologia	:	"Unidad Oftalmologia"	,
	cuidadosintensivos	:	"Unidad Cuidados Intensivos"	,
	radiodiagnostico	:	"Unidad Radiodiagnostico"	,
	ap	:	"Unidad AP"	,
	pediatría	:	"Unidad Pediatria"	,
	cmax	:	"Unidad Cirugia Maxilofacial"	,
	cgral	:	"Unidad Cirugia General"	,
	urologia	:	"Unidad Urologia"	,
	"misiones_a1": "Misiones",

	bacteriemias: "Proyecto bacteriemias ",
	neurofisiologia: "Proyecto Neurofisiología",

	app_direccion: "Dashboard Dirección",
	app_enf: "APP Enfermería",
	unicom: "Proyecto UNICOM",
	primavera: "175",
};