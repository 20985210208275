import {transformRow} from "../../../../utils/TransformFormDashboard";

export const getFormData = (data) => {
  return ({
    tipo: "OPERACION_6",
    id_form: data.id_form,
    id_patient: data.id_patient,
    filter: data.filter
  });
}

export const saveFormData = (data) => {
  return ({
    tipo: "OPERACION_7",
    form: data.id_form,
    paciente: data.id_patient,
    data: transformFormData(data.form, data.idTable),
    idTable: data.idTable,
  });
}

export const saveFormDataWithParams = (data) => {
  return ({
    tipo: "OPERACION_7",
    form: data.id_form,
    paciente: data.id_patient,
    data: transformFormData(data.form, data.idTable),
    idTable: data.idTable,
    params: data.params
  });
}


//Transformación al envío de los elementos remoteSearch
const transformFormData = (data, idTable) => {
  Object.keys(data).forEach(o => {
    if(data[o] !== undefined && data[o] !== '' && data[o] !== null) {
      // if(data[o].constructor.name === 'Moment')
      //   data[o] = data[o].format('YYYY-MM-DD');
      if((data[o][0]?.key || data[o].constructor.name == 'Object') && !idTable) {
        //Si no es una table
        if(Array.isArray(data[o]))
          data[o] = data[o].map(transformObjectToString).toString()
        else
          data[o] = transformObjectToString(data[o])
      }
      else {
        //Si es una tabla
        if(data[o].constructor.name == 'Object')
          data[o] = transformFormData(data[o], false)
      }
    }
  })
  return data;
}

//Transforma los elementos de tipo selec complejo {key, value} a string key - value
const transformObjectToString = (object) => {
   return object.key + " - " + object.label
}

export const printFormData = (data) => {
  return ({
    tipo: data.operation,
    form: data.id_form,
    paciente: data.id_patient,
    data: data.form
  });
}


export const responseForm = ({ data = { form: {} } }) => {
  const { form } = data;
  return ({
    id: form.id,
    title: form.title,
    type: form.type,
    rows: form.rows?.map(transformRow),
  });
}
export const responseSaveForm = ({ data = {}}) => {
  return (data);
}