import React from 'react'
import Card from "antd/es/card";

export class Widget {

  constructor(obj={}) {
    this.title = obj.title;
    this.type = obj.type ?? 'default';
    this.size = obj.size;
  }

  getComponent () {
    return () => (
      <Card title={this.title}>
        <span> Widget no implement - {this.title} -</span>
      </Card>);
  }

  transformToWidget = (data) => {
    console.error('Widget no implement');
    this.title = data.title;
    this.size = data.size;
    return this;
  }
}