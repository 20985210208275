import {Widget} from "./index";
import React from "react";
import {FormWithAction} from "../../components/shared-components/FormWithAction";

export class WidgetFormAction extends Widget {

  constructor(obj = {}) {
    super(obj);

  }

  getComponent({formData} = {}) {
    return () => (
      <FormWithAction
        fields={this.fields}
        buttons={this.buttons}
        title={this.title}
        form={formData}
      />
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.fields = data.fields;
    this.buttons = data.buttons;

    return this;
  }
}