import React, {useState} from 'react'
import {Col, Form, Input, message, Upload} from "antd";
import {Field} from "./index";
import Utils from "../../utils";
import {QuestionCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {LabelWithTooltip} from "./components/LabelWithTooltip";
import {FieldBase} from "./components/FieldBase";
import Button from "antd/es/button";
import {env} from "../../configs/EnvironmentConfig";

export class FieldFile extends Field {

  constructor(obj={}) {
    super(obj);
  }



  getComponent (form) {

    return () => {
      const [fileList, setFileList] = useState(this.value || form.getFieldValue(this.name) ? [{
        uid: '-1',
        name: this.value === "" ? form.getFieldValue(this.name) : this.value,
        status: 'done',
      }] : [])
      const idProject = Utils.getIdProject()
      const idPatient = Utils.getPatientLocation()
      const props = {
        name: 'file',
        multiple: false,
        action: env.API_FILES+'/upload',
        onPreview: (file)=> window.open(env.API_FILES+"/files/"+idProject+"/"+idPatient+"/"+file.name),
        data: {
          patient:idPatient,
          project:idProject
        },
        fileList: fileList,
        onChange: (info) => {
          if (info.file.status != 'error') {
            console.log(info.file.status)
            if(info.file.status == "done") {
              message.success(`${info.file.name} file uploaded successfully`);
            }
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            form.setFieldsValue({[this.name]: info.file.name})

            setFileList(fileList)
            console.log(fileList)
          }
         if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };
      return (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help}>
        <Upload {...props} fileList={fileList}>
          <Button>
            <UploadOutlined /> Adjuntar
          </Button>
        </Upload>
      </FieldBase>)};
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.value = data.value ?? data.defaultValue ?? "";
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    return this;
  }
}