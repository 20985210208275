import { Widget } from './index';
import React from "react";
import Card from "antd/es/card";
import Plot from 'react-plotly.js';


export class WidgetMap extends Widget {

  constructor(obj={}){
    super(obj);
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <Plot
          style={{width: "100%", height: this.height}}
          useResizeHandler={true}
          onClick={(data) => console.debug("onClick", data)}
          data={this.data}
          layout = {{
            showlegend: true,
            legend: {
              x: 0,
              y: 1
            },
            responsive: true,
            useResizeHandler: true,
            autosize: true,
            dragmode: "zoom",
            mapbox: {style: "open-street-map", center: this.center ?? {lat: 37.5, lon: -4.7}, zoom:6},
            margin: {r: 0, t: 0, b: 0, l: 0}
          }}
        />
      </Card>
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.data = data.data;
    this.height = data.height ? data.height : 500
    this.center = data.center
    return this;
  }
}