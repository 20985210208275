import React from 'react'
import {Col, Form, TreeSelect} from "antd";
import {Field} from "./index";
import Utils from "../../utils";



export class FieldTreeSelect extends Field {

  constructor(obj={}) {
    super(obj);
  }


  getComponent (form) {
    return () => (
      <Col className={!Utils.evaluateFormula(this.condition, form) ? "display-none" : "visible"} xs={24} sm={24} md={this.size}>

        <Form.Item
          label={this.label}
          name={this.name}
        >
          <TreeSelect
            showSearch
            allowClear
            filterTreeNode={(search, item) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            treeData={this.options} />
        </Form.Item>
      </Col>);
  }

  transformToField = (data) => {
    this.options = data.options;
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    return this;
  }
}