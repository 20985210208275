import React, {useRef, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd';
import ApexChart from "react-apexcharts";
import {
	apexLineChartDefaultOption,
	apexBarChartDefaultOption,
	apexAreaChartDefaultOption,
	apexTemporalChartDefaultOption,
	apexTreemapChartDefaultOption,
	apexRangeBarChartDefaultOption,
	apexHeatMapChartDefaultOption, apexBoxPlotChartDefaultOption
} from 'constants/ChartConstant';
import ReactResizeDetector from 'react-resize-detector';

const titleStyle = {
	position: 'absolute',
	zIndex: '1'
}

const extraStyle ={
	position: 'absolute',
	zIndex: '1',
	right: '0',
	top: '-2px'
}

const getChartTypeDefaultOption = type => {
	switch (type) {
		case 'line':
			return apexLineChartDefaultOption
		case 'bar':
			return apexBarChartDefaultOption
		case 'area':
			return apexAreaChartDefaultOption
		case 'temporal':
			return apexTemporalChartDefaultOption
		case 'treemap':
				return apexTreemapChartDefaultOption
		case 'rangeBar':
			return apexRangeBarChartDefaultOption
		case 'heatmap':
			return apexHeatMapChartDefaultOption
		case 'boxPlot':
			return apexBoxPlotChartDefaultOption
		default:
			return apexLineChartDefaultOption;
	}
}

const ChartWidget = ({title, series, width, height, xAxis, customOptions, card, type, extra}) =>  {
  const [options, setOptions] = useState(JSON.parse(JSON.stringify( getChartTypeDefaultOption(type))));
  const [loading, setLoading] = useState(false);
	const isMobile = window.innerWidth < 768
	const setLegendOffset = () => {
		if(chartRef.current) {
			const lengend = chartRef.current.querySelectorAll('div.apexcharts-legend')[0]
			lengend.style.marginRight = `${isMobile ? 0 : extraRef.current?.offsetWidth}px`
			if (isMobile) {
				lengend.style.position = 'relative'
				lengend.style.top = 0
				lengend.style.justifyContent = 'start';
				lengend.style.padding = 0;
			}
		}
	} 

	useEffect(() => {
    setLegendOffset()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

  useEffect(() => {
      if(loading){
        setLoading(false)
      }
  }, [loading])


  useEffect(() => {
    let optionsClone = JSON.parse(JSON.stringify(options));
    optionsClone.xaxis.categories = xAxis
    if(customOptions) {
      customOptions.xaxis = {
        ...customOptions.xaxis,
          categories: xAxis
      }
      if(type === 'heatmap')
      	customOptions.tooltip = {
      		...customOptions.tooltip,
					custom: function({series, seriesIndex, dataPointIndex, w}) {
						let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
						return '<div style="margin: 5px">' +
							'<span>' + data.name ?? data.x + '</span>' +
							'</div>'
					}
				}
      if(type == 'rangeBar' && customOptions?.tooltip?.subtitle){
				customOptions.tooltip = {
					...customOptions.tooltip,
					y: {
						formatter: function(value, {w, seriesIndex, dataPointIndex}) {
							return w.globals.seriesZ[seriesIndex][dataPointIndex]+":"
						}
					}
				}
			}
      optionsClone = {...optionsClone, ...customOptions }
    }
    setOptions(optionsClone);
    setLoading(true);
  }, [xAxis])

	const extraRef = useRef(null);
	const chartRef = useRef();


	
	const onResize = () => {
		setTimeout(() => {
			setLegendOffset()	
		}, 600);
	}

	const renderChart = (
		<ReactResizeDetector onResize={onResize()}>
			<div className="chartRef" ref={chartRef}>
				<ApexChart options={options} type={type == 'temporal' ? 'area' : type} series={series} width={width} height={height}/>
			</div>
		</ReactResizeDetector>
	)

	return (
		<>
			{card ? 
				<Card title={title}>
					<div className="position-relative">
						{/*{<div style={!isMobile ? titleStyle : {}}>{title}</div> && <h4 className="font-weight-bold" style={!isMobile ? titleStyle : {}}>{title}</h4>}*/}
						{extra && <div ref={extraRef} style={!isMobile ? extraStyle : {}}>{extra}</div>}
						{!loading && renderChart}
					</div>
				</Card>
				: 
				!loading && renderChart
			}
		</>
	)
}

ChartWidget.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	series: PropTypes.array.isRequired,
	xAxis: PropTypes.array,
	customOptions: PropTypes.object,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	card: PropTypes.bool,
	type: PropTypes.string
}

ChartWidget.defaultProps = {
	series: [],
	height: 300,
	width: '100%',
	card: true,
	type: 'line'
};

export default ChartWidget
