import React, { Component } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  CheckOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

export class CopyToolbar extends Component {

  state = {
    copied: false,
    copyTooltipVisible: false,
  };

  handleCodeCopied = () => {
    this.setState({ copied: true });
  };

  onCopyTooltipVisibleChange = visible => {
    if (visible) {
      this.setState({
        copyTooltipVisible: visible,
        copied: false,
      });
      return;
    }
    this.setState({
      copyTooltipVisible: visible,
    });
  };

  render() {
    const { id } = this.props;
    const { copied, copyTooltipVisible } = this.state;

    return (
      <div className="code-box-actions">
        <span className={`code-box-icon mr-3 ${copied && copyTooltipVisible ? 'text-success' : ''}`}> 
          <Tooltip 
            title={copied ? 'Hecho' : 'Copiar'}
            visible={copyTooltipVisible}
            onVisibleChange={this.onCopyTooltipVisibleChange}
          >
            {/*CopyToclipboard with tabs and new lines from table*/}
            <CopyToClipboard
                text={document.getElementById(id)?.innerText}
                onCopy={this.handleCodeCopied}
            >
              {copied ? <CheckOutlined /> : <SnippetsOutlined />}
            </CopyToClipboard>
          </Tooltip>
        </span>

      </div>
    );
  }
}

export default CopyToolbar;
